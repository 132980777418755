@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

:root {
  /* paleta 2 */
  --primary-color: #f0433a;
  --second-color: #c9283e;
  --thirth-color: #820333;
  --fourth-color: #fffafa;
  --fiveth-color: #171416;

  --body: #dee5e7;
  --navbar: #171416;

  --table-thead: #171416;
  --tr-id: #158c79;
  --even: #c9d5d8;
  --table-bg: rgba(32, 74, 68, 0.2);

  /* Table */
  --table-bg: #7b9db5;

  /* form */
  --form-bg: #24303c;
  --form-input-border: #1f53c5;

  /* button Add */
  --button-add-color: #dcecfd;
  --button-add-bg: #2a6fe6;
  --button-add-border: #1f53c5;

  /* button confirm */
  --button-confirm-color: #1eae95;
  --button-confirm-bg: #158c79;

  /* button edit */
  /* --button-edit-color: #ffca2c; */
  --button-edit-color: #43482c;
  /* --button-edit-color-bg: #fff4c2; */
  --button-edit-color-bg: #ebecdb;
  --button-edit-color-border: #a35305;

  /* button-delete */
  --button-delete-color: #c9283e;
  --button-delete-color-bg: #fde6e6;

  --first-color-bg: rgba(32, 74, 68, 0.5);
  --second-color: #1eae95;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto" sans-serif;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  /* background-color: #a7a3a3; */
  background-color: #e6dcdc;
}

/* Modal */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fondo oscuro con transparencia */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilo para el modal */
.confirm-delete-modal {
  width: 300px;
  background: #fff; /* Fondo blanco para el modal */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra */
  text-align: center;
}

/* header */

.container-header {
  background-color: var(--navbar);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-title-nav {
  padding: 10px;
}

.content-title-nav h1 {
  color: #fff;
  font-size: 20px;
}

.container-login {
  height: 100vh;
  display: flex;
  /* background-color: #158c79; */
}

.container-image-login,
.container-form-login {
  flex: 1;
  margin: 0;
  padding-top: 20px;
}

.container-image-login {
  position: relative;
  height: 100%;
  background-image: linear-gradient(
      rgba(19, 91, 236, 0.2),
      rgba(19, 91, 236, 0.2)
    ),
    url(/src/images/algodon1.jpg);
  background-size: cover;
  background-position: center;
}

.container-form-login img {
  width: 200px;
  margin-bottom: 0;
}

.container-form-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.container-add-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-add-user > .error-message {
  font-size: 20px;
}

.container-add-user > .form {
  margin-bottom: 30px;
  width: 50%;
}

.container-register-user {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas de tamaño igual */
  gap: 10px;
}

.container-label-importada {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Dos columnas de tamaño igual */
  gap: 10px;
}

.container-home {
  height: 90vh;
  display: flex;
  /* background-color: #158c79; */
}

.container-image-home {
  width: 100%;
  height: 100%;
  /* margin: 0px 30px 0px 30px; */
  background-image: url(/src/images/algodon2.jpeg);
  background-size: cover;
  background-position: center;
}

nav {
  max-width: 1280px;
  margin-right: 20px;

  font-size: 16px;
}

.menu-horizontal {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.menu-horizontal > li > a {
  display: block;
  padding: 15px 20px;
  color: white;
  text-decoration: none;
}

.menu-horizontal > li:hover {
  background: var(--second-color);
}

.menu-vertical {
  position: absolute;
  display: none;
  list-style: none;
  width: 200px;
  background-color: var(--first-color-bg);
}

.menu-horizontal li:hover .menu-vertical {
  display: block;
}

.menu-vertical li:hover {
  background-color: black;
}

.menu-vertical li a {
  display: block;
  color: white;
  padding: 15px 15px 15px 20px;
  text-decoration: none;
}

/* Main */

.message {
  width: 400px;
  padding-top: 20px;
  color: var(--button-add-bg);
}

/* Agremiacion */
.container-agremiacion,
.container-cosecha {
  display: flex;
  justify-content: center;
  text-align: center;
}

.content-title {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.content-title h1 {
  text-align: left;
  margin: 30px 0 10px 0;
}

.title {
  text-align: left;
  /* font-size: 30px; */
  margin: 30px 0 30px 0;
  /* text-shadow: 10px 5px 5px black; */
}

/* table */

.container-table {
  display: flex;
  justify-content: center;
}

.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  /* background-color: var(--tr-id); */
  background-color: var(--form-bg);
  color: #fff;
  text-align: left;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
}

.content-table td {
  text-align: left;
}

.content-table tbody tr {
  /* border-bottom: 1px solid #dddddd; */
  border-bottom: 1px solid #f5f8fa;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #d1dde6;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

tr:hover td {
  background-color: var(--table-bg);
}

/* buttons */

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.btn {
  width: 70px;
  height: 30px;
  padding: 3px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;
}

.btn-add {
  background: var(--button-add-color);
  width: 80px;
  border: solid 1px var(--button-add-border);
  color: var(--button-add-border);
  text-decoration: none;
}

.btn-add:hover {
  background: var(--button-add-border);
  color: #fff;
}

.btn-confirm {
  background: var(--button-confirm-color);
  width: 80px;
  margin-top: 5px;
  margin-right: 5px;
  border: solid 1px var(--button-confirm-color);
  color: var(#fff);
  text-decoration: none;
}

.btn-confirm:hover {
  border-color: var(--button-confirm-bg);
  color: #fff;
}

.btn-edit {
  margin-right: 5px;
  /* padding: 3px; */
  background: var(--button-edit-color-bg);
  border: solid 1px var(--button-edit-color-border);
  color: var(--button-edit-color-border);
  text-decoration: none;
}

.btn-edit:hover {
  background: var(--button-edit-color);
  color: #fff;
  border: solid 1px var(--button-edit-color);
}

.btn-delete {
  background: var(--button-delete-color-bg);
  width: 80px;
  border: solid 1px var(--button-delete-color);
  color: var(--button-delete-color);
  text-decoration: none;
}

.btn-delete:hover {
  background: var(--button-delete-color);
  color: #fff;
}

.btn-access-login {
  width: 100%;
}

.btn-access-login:hover {
  background: var(--button-confirm-bg);
  color: #fff;
}

.btn-label {
  width: 100%;
  height: 42px;
  background: var(--button-confirm-color);
  margin-top: 15px;
  margin-right: 5px;
  border: solid 1px var(--button-confirm-color);
  color: var(#fff);
  text-decoration: none;
}

.btn-label:hover {
  background: var(--button-confirm-bg);
  color: #fff;
}

/* Forms */

.form {
  width: 550px;
  background: #24303c;
  padding: 30px;
  margin: auto auto 10px auto;
  margin-top: 30px;
  border-radius: 4px;
  color: #fff;
  box-shadow: 7px 13px 37px #000;
}

.form.form-login {
  width: 400px;
  height: 300px;
}

.form h3 {
  margin-bottom: 40px;
  text-align: center;
}

.form label {
  display: flex;
  margin-bottom: 10px;
}

.controls {
  width: 100%;
  background: #24303c;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 16px;
  border: 1px solid #1f53c5;
  font-size: 18px;
  color: #c7c7c7;
}

.content-form-buttons {
  display: flex;
  justify-content: center;
}

.content-form-buttons .btn-add {
  margin-right: 5px;
}

.select {
  background: transparent;
  background-color: #24303c;
  color: #fff;
  border: none;
  font-size: 16px;
  width: 240px;
  height: 40px;
  padding: 5px;
  border: 1px solid #1f53c5;
  border-radius: 5px;
  margin-bottom: 10px;
}

.container-error-message {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  font-size: 17px;
}

.container-error-message p {
  margin-left: 50px;
  font-size: 30px;
}

.error-message {
  display: flex;
  justify-items: center;
  color: rgb(238, 10, 10);
  margin-bottom: 16px;
  font-size: 20px;
}

.success-message {
  display: flex;
  justify-items: center;
  color: #60e147; /*#2fed2e*/
  margin-bottom: 16px;
  font-size: 30px;
}

.container-etiqueta {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.container-etiqueta form {
  margin: 0;
}

.pdf {
  width: 600px;
  height: 500px;
  margin-left: 10px;
  border: none;
}

/* footer*/
.container-footer {
  position: fixed;
  margin-top: 10px;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #2d3f50;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  text-decoration: none;
}

.logo-footer img {
  width: 100px;
  /* height: 500px; */
}

.contact-info {
  text-decoration: none;
}

.container-marcela {
  width: 500px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  /* background: #e6dcdc; */
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5522584033613445) 15%,
    rgba(230, 220, 220, 1) 100%
  );
}

.container-pdf-modal {
  background-color: #1f53c5;
}

.pdf-modal {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */

  width: 800px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5522584033613445) 15%,
    rgba(23, 20, 22, 1) 100%
  );
  /* background: #171416; */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra */
  text-align: center;
}

.pdf-modal div {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}

.pdf-title {
  color: white;
  padding-bottom: 20px;
}

.container-btn-pdf {
  width: 100%;
  display: flex;
  justify-content: end;
}

/* .input-label-importada{
  width: 10px;

} */
